@import '../../styles/colors';

#sidebar {
  visibility: visible;
  opacity: 1;
  overflow: hidden;
  z-index: 1300;

  &.hidden {
    .background {
      opacity: 0;
      visibility: hidden;
    }

    .sidebar-content {
      transform: translateX(100%);
    }
  }

  .background {
    z-index: 1400;
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    transition: 300ms all ease-out;
  }

  .sidebar-content {
    overflow: hidden;
    position: fixed;
    right: 0;
    top: 5rem;
    bottom: 0;
    width: 30rem;
    max-width: 100vw;
    z-index: 1500;
    transform: none;
    transition: 300ms ease-out transform;
    will-change: tranform;

    @media only screen and (max-width: 650px) {
      top: 4rem;
      width: 100vw;
    }

    @media only screen and (max-width: 550px) {
      width: 100vw;
    }

    > * {
      transition: 300ms ease-out transform;
    }
  }

  .main-menu-items {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: none;
    display: flex;
    flex-direction: column;
    background-color: $color;

    &.hidden {
      transform: translateX(-100%);
    }

    .main-menu-item {
      padding: 1.5rem;
      cursor: pointer;
      color: $high-contrast;
      border-bottom: 1px solid $contrast;

      &:hover {
        background-color: $hover;
      }

      span {
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 2px;
      }

      * {
        font-size: 1.6rem;
      }

      .icon {
        width: 2rem;
        margin-right: 1.5rem;
        margin-left: 0.5rem;
      }
    }
  }

  .sub-menu {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    transform: translateX(0);
    background-color: $color;

    &.hidden {
      transform: translateX(100%);
    }

    .search-input-container {
      width: 100%;
      height: 3.5rem;
      display: flex;
      align-items: center;
      background-color: white;
      align-items: center;
      line-height: auto;

      .search-icon-container {
        flex: 0 0 4rem;
        text-align: center;
      }

      .icon.fa-search {
        font-size: 1.6rem;
        color: #222;
      }

      input {
        height: 100%;
        flex: 1;
        border: 0;
        border-radius: 0;
        font-size: 1.6rem;
        padding-right: 1rem;
        margin: 0;
        overflow: hidden;
        -webkit-appearance: none;

        &::-webkit-search-cancel-button {
          cursor: pointer;
          height: 16px;
          width: 16px;
          background-image: url('../../images/clear-input.svg');
        }
      }
    }

    .sub-menu-header {
      padding: 1rem 1.5rem;
      cursor: pointer;
      color: $high-contrast;
      border-bottom: 1px solid $contrast;
      background-color: $color;

      &:hover {
        background-color: $hover;
      }

      span {
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 2px;
      }

      * {
        font-size: 1.6rem;
      }

      .icon {
        width: 2rem;
        margin-right: 1.5rem;
        margin-left: 0.5rem;
      }
    }

    .sub-menu-content {
      flex: 1;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
    }
  }

  .menu-items {
    .color-result-item {
      padding: 1.5rem 1.25rem;
      font-size: 1.6rem;
      line-height: 2.1rem;
      font-weight: normal;
      letter-spacing: 1px;
      cursor: pointer;
      text-transform: uppercase;
    }

    .menu-item {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      height: 10rem;
      align-items: center;
      cursor: pointer;
      padding: 2rem 0;
      position: relative;

      .color-name {
        font-size: 1.5rem;
        text-align: center;
        margin: 0;
      }

      .menu-item-overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: stretch;

        .panel {
          flex: 1;
          font-weight: 300;
          opacity: 0;
          transition: 120ms opacity ease-out;
          display: flex;
          align-items: flex-start;

          .panel-text {
            display: inline-block;
            background: $color;
            color: $high-contrast;
            padding: 0.375rem 0.75rem;
            font-size: 1.2rem;
            border-bottom: 1px solid $high-contrast;
          }

          &.left {
            justify-content: flex-start;

            .panel-text {
              border-bottom-right-radius: 6px;
              border-right: 1px solid $high-contrast;
            }
          }

          &.right {
            justify-content: flex-end;
            .panel-text {
              border-bottom-left-radius: 6px;
              border-left: 1px solid $high-contrast;
            }
          }

          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }
}

.footer-left,
.footer-right {
  position: absolute;
  bottom: 6px;
  font-size: 1.2rem;
  line-height: 1.2rem;
}

.footer-right {
  right: 6px;
}

.footer-left {
  left: 6px;
}

.footer-row {
  height: 6rem;
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .icon {
    font-size: 3rem;
    color: $high-contrast;
    cursor: pointer;
    fill: $high-contrast;
    margin: 1rem;

    &:hover {
      color: $contrast;
      fill: $contrast;
    }

    &.kofi-icon {
      margin-bottom: 0.5rem;
    }
  }
}

.help-menu-content {
  color: $high-contrast;
  padding: 0 1.25rem 1rem 1.25rem;

  h4 {
    font-size: 1.8rem;
    margin: 2rem 0 0.5rem 0;
  }

  h5 {
    font-size: 1.5rem;
    margin: 1.2rem 0 0.25rem 0;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  p {
    margin: 0 0 1rem 0;
    font-size: 1.4rem;
  }

  .feature-container {
    height: auto;
    overflow: hidden;

    &.closed {
      height: 0;
    }
  }

  a {
    color: $high-contrast;
  }

  ul {
    padding-left: 1.4rem;
  }

  li {
    font-size: 1.2rem;
  }

  code {
    font-size: 1.3rem;
    background-color: $opposite-contrast;
    padding: 0.1rem 0.3rem;
  }

  .plus-button {
    margin-right: 1rem;
  }
}
