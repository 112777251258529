@import '../../styles/general';

.content-background {
  min-height: calc(100vh - 5rem);
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color $transition-time linear;
  will-change: background-color;
}

.body-content {
  .input-container {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 1rem 1rem 0 1rem;

    .color-input {
      width: 27rem;
      display: flex;

      input {
        display: inline-flex;
        flex: 7;
        -webkit-flex: 7 1;
        height: 3rem;
        border: 2px solid black;
        border-right: 0;
        border-radius: 5px 0 0 5px;
        margin: 0;
        padding: 0 0.75rem;
        overflow: visible;
        -webkit-appearance: none;
        outline-offset: -2px;

        &::-webkit-search-cancel-button {
          cursor: pointer;
          height: 16px;
          width: 16px;
          background-image: url('../../images/clear-input.svg');
        }
      }

      button {
        flex: 0 0 6rem;
        -webkit-flex: 0 0 6rem;
        cursor: pointer;
        border: 2px solid black;
        border-radius: 0 5px 5px 0;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.4rem;
        line-height: 1.4rem;
        color: #272a29;
        margin: 0;
      }
    }
  }

  .container {
    display: grid;
    grid-template-columns: repeat(9, auto);
    border-radius: 10px;
    padding: 1rem;
    grid-gap: 1rem;
  }
}

@media only screen and (max-width: 1250px) {
  .body-content {
    .input-container {
      .color-input {
        width: 23rem;

        button {
          flex: 0 0 5rem;
        }
      }

      .color-name {
        font-size: 1.8rem;
      }
    }
  }
}

@media only screen and (max-width: 950px) {
  .body-content {
    .container {
      grid-template-columns: repeat(6, auto);
    }

    .input-container {
      flex-direction: column-reverse;
      .color-input {
        width: 100%;
        margin-bottom: 0;
      }

      .color-name {
        width: 100%;
        text-align: left;
        margin-bottom: 1rem;
      }
    }
  }
}

@media only screen and (max-width: 650px) {
  .page {
    padding-top: 4rem;

    .body-content {
      .container {
        grid-template-columns: repeat(4, auto);
        width: 100vw;
      }
    }
  }
}

.body-content.split {
  .container {
    grid-template-columns: repeat(6, auto);
  }

  .input-container {
    flex-direction: column-reverse;
    .color-input {
      width: 100%;
      margin-bottom: 0;
    }

    .color-name {
      width: 100%;
      text-align: left;
      margin-bottom: 1rem;
    }
  }

  @media only screen and (max-width: 1150px) {
    .container {
      grid-template-columns: repeat(4, auto);
      width: 50vw;
    }
  }

  @media only screen and (max-width: 720px) {
    .container {
      grid-template-columns: repeat(3, auto);
      grid-gap: 0.75rem;
      padding: 0.75rem;
    }

    .input-container {
      padding: 0.75rem 0.75rem 0 0.75rem;

      .color-name {
        margin-bottom: 0.75rem;
      }
    }
  }
}
