@import '../../styles/colors';
@import '../../styles/general';

#header {
  height: 5rem;
  text-align: center;
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 500;
  transition: $transition-time all linear;
  z-index: 2000;
  border-bottom: 2px solid $contrast;
  background-color: $color;

  * {
    transition: $transition-time all linear;
  }

  .left-content {
    text-align: center;
    margin-left: 3rem;
    display: flex;
    align-items: center;
    cursor: default;
    color: $contrast;

    h1 {
      font-size: 2rem;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 2px;
    }

    .icon {
      display: grid;
      grid-template-columns: 12px 12px;
      grid-gap: 2px;
      margin-right: 0.8rem;
      padding: 1px;
      cursor: pointer;

      .icon-dot {
        height: 12px;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        border-radius: 2px;
      }
    }
  }

  .right-content {
    display: inline-flex;
    align-items: center;
    margin-right: 3rem;

    .button-section {
      display: flex;
      margin-right: 3rem;
    }
  }

  .icon-button {
    margin-left: 0.75rem;
    border-radius: 5px;
    padding: 6px 12px;
    font-size: 1.3rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: 600;
    border: 2px solid $contrast;
    color: $contrast;
    user-select: none;

    &.active {
      color: $opposite-contrast;
      background-color: $contrast;

      i {
        color: $opposite-contrast;
        padding: 2px 0;
      }

      span {
        color: $opposite-contrast;
      }
    }

    i {
      font-size: 1.4rem;
      color: $contrast;
    }

    span {
      margin-left: 10px;
      color: $contrast;
    }
  }

  .menu-icon {
    transition: color $transition-time linear;
  }

  @media only screen and (max-width: 900px) {
    .right-content {
      .icon-button {
        .icon-button-text {
          display: none;
        }
      }
    }
  }

  @media only screen and (max-width: 650px) {
    height: 4rem;

    .left-content {
      margin-left: 1rem;

      h1 {
        font-size: 1.5rem;
        letter-spacing: 0;
      }
    }

    .right-content {
      display: inline-flex;
      align-items: center;
      margin-right: 1rem;

      .button-section {
        margin-right: 1rem;
      }

      .bars.big.icon {
        margin-left: 0;
      }

      .icon-button {
        padding: 6px 7px;

        .random-button-text {
          display: none;
        }
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .right-content {
      .button-section {
        .icon-button {
          margin: 0;

          &.split-button {
            display: none;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 370px) {
    .left-content {
      .icon {
        display: none;
      }
    }

    .right-content {
      .button-section {
        margin-right: 0.5rem;
      }
    }
  }
}
