@import '../../styles/general';

.color-square {
  & > div {
    display: block !important;
  }

  .color-tile {
    padding: 0.5rem 1rem;
    width: 6rem;
    height: 6rem;
    border-radius: 5px;
    cursor: pointer;
    // transition: $transition-time background-color ease-in-out;
    background-color: #222;
    border: 0;
    outline: none;
  }

  @media only screen and (max-width: 1250px) {
    .color-tile {
      width: 5rem;
      height: 5rem;
    }
  }

  @media only screen and (max-width: 650px) {
    .color-tile {
      border-radius: 4px;
      width: calc(100vw / 4 - 5 / 4 * 1rem);
      height: calc(100vw / 4 - 5 / 4 * 1rem);
    }
  }
}

.body-content.split {
  .color-tile {
    width: 5rem;
    height: 5rem;
  }

  @media only screen and (max-width: 1150px) {
    .color-tile {
      border-radius: 4px;
      width: calc(50vw / 4 - 5 / 4 * 1rem);
      height: calc(50vw / 4 - 5 / 4 * 1rem);
    }
  }

  @media only screen and (max-width: 720px) {
    .color-tile {
      width: calc(50vw / 3 - 4 / 3 * 0.75rem);
      height: calc(50vw / 3 - 4 / 3 * 0.75rem);
    }
  }
}

.tooltip-title {
  font-size: 18px;
  margin-top: 13px;
  margin-bottom: 11px;
  font-weight: 600;
  letter-spacing: 1px;
}

.tooltip-sub-title {
  letter-spacing: 1px;
  margin-bottom: 0.2rem;
}

.popup-button {
  margin: 11px;
  margin-bottom: 0;

  &:last-of-type {
    margin-bottom: 11px;
  }

  button {
    width: 14rem;
    letter-spacing: 1px;
    transition: 80ms background-color linear;

    &:hover {
      background-color: #222;
    }
  }
}

.tippy-tooltip {
  padding: 0;
}

.tippy-tooltip.dark-border-theme {
  background-clip: padding-box;
  border: 2px solid white;
}

.tippy-popper[x-placement^='top'] [x-arrow] {
  border-top-color: white;
}

.tippy-popper[x-placement^='bottom'] [x-arrow] {
  border-bottom-color: white;
}

.tippy-popper[x-placement^='right'] [x-arrow] {
  border-right-color: white;
}

.tippy-popper[x-placement^='left'] [x-arrow] {
  border-left-color: white;
}
