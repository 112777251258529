.plus-button {
  width: 22px;
  height: 22px;
  position: relative;
  .line {
    display: block;
    width: 100%;
    height: 4px;
    background: #000;
    position: absolute;
    transition: all 250ms;
    border-radius: 2px;
    opacity: 1;
    top: 50%;
    transform: translateY(-50%);

    &:nth-child(1) {
      transform: translateY(-50%) rotate(-90deg);
    }
  }
  &.close {
    .line {
      &:nth-child(1) {
        transform: translateY(-50%) rotate(180deg);
      }

      &:nth-child(2) {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }
}
