@import '@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '@fortawesome/fontawesome-free/scss/solid.scss';
@import '@fortawesome/fontawesome-free/scss/brands.scss';
@import '../../styles/general';

* {
  box-sizing: border-box;

  &:focus {
    outline: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

html {
  font-size: 14px;

  @media only screen and (min-width: 2000px) {
    font-size: 16px;
  }
}

input {
  font-size: 16px;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration,
input[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

input,
input:before,
input:after {
  user-select: initial;
}

#App {
  transition: $transition-time background-color ease-in-out;
  will-change: background-color;
  background-color: #222;
}

.main-container {
  position: relative;
}

.button {
  font-size: 1.2rem;
  padding: 0.5rem 1rem;
  background-color: transparent;
  border: 2px solid;
  color: white;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
}

.button-group {
  margin: 1rem !important;
  display: flex !important;
  > * {
    margin: 0 !important;
    border-radius: 0 !important;
    border-width: 2px 0 2px 2px !important;

    &:first-child {
      border-top-left-radius: 5px !important;
      border-bottom-left-radius: 5px !important;
    }

    &:last-child {
      border-top-right-radius: 5px !important;
      border-bottom-right-radius: 5px !important;
      border-right-width: 2px !important;
    }
  }
}

.page {
  display: flex;
  min-height: 100vh;
  justify-content: space-evenly;
  align-items: stretch;
  padding-top: 5rem;
}

.color-name {
  font-size: 2rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 2rem;
  text-align: center;
  transition: $transition-time color ease-in-out;
}

label {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.italic {
  font-style: italic;
}

@media only screen and (max-width: 650px) {
  .page {
    padding-top: 4rem;
  }

  .content-background {
    min-height: calc(100vh - 4rem);
  }
}
