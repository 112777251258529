.loading {
  position: fixed;
  z-index: 9999;
  overflow: show;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  will-change: opacity;
  transition: 80ms all ease-out;

  &.hidden {
    visibility: hidden;
    opacity: 0;

    svg {
      display: none;
    }
  }
}
